@import '../../../../scss/theme-bootstrap';

[data-component='search-gnav-input-field'] > .elc-spinner-wrapper {
  height: 30px;
  width: auto;
  .elc-spinner-container > div {
    background-color: transparent;
  }
}

.elc-search-icon-wrapper,
.elc-search-close-icon-wrapper,
.elc-search-suggestion-link,
.elc-search-results-button,
.elc-search-article__img-wrapper,
.elc-search-article__link {
  &:focus {
    outline: $color-focus-default auto 5px;
    outline: -webkit-focus-ring-color auto 5px;
  }
}

.gnav-search__tags-block {
  visibility: hidden;
  height: 80%;
  overflow: auto;
  position: fixed;
  z-index: 1001;
  width: 100%;
  background-color: $color-cream;
  #{$ldirection}: 0;
  top: 147px;
  border: none;
  box-sizing: border-box;
  padding: 56px 20px 48px;
}

.gnav-search__tags-body {
  .gnav-search__tags-block {
    visibility: visible;
    &.elc-voice-search-overlay-displayed {
      z-index: 999;
    }
    @include breakpoint($medium-up) {
      height: auto;
      padding: 64px 64px 72px 64px;
    }
    @include breakpoint($landscape-up) {
      overflow: hidden;
      top: 164px + $gnav-height;
    }
    .button-links-formatter__carousel-item .button--secondary {
      @include button--secondary;
      @include title--h6;
      height: 40px;
      padding: 0 24px;
      line-height: 40px;
      box-shadow: inset 0 0 0 1px $color-cream-stroke;
    }
  }
  &.content-block-sitewide-banner--enabled {
    .gnav-search__tags-block {
      @include breakpoint($landscape-up) {
        top: 164px + $gnav-height + $sitewide-banner-height;
      }
    }
  }
  &.gnav-header-block--sticky,
  &.gnav-offers-hidden:not(.content-block-sitewide-banner--enabled) {
    .gnav-search__tags-block {
      @include breakpoint($landscape-up) {
        top: 164px + $menu-height;
      }
    }
  }
  &.gnav-offers-hidden.content-block-sitewide-banner--enabled {
    .gnav-search__tags-block {
      @include breakpoint($landscape-up) {
        top: 164px + $menu-height + $sitewide-banner-height;
      }
    }
  }
}

.sd-search-gnav-input-field {
  &:hover,
  .search-overlay-displayed & {
    @include breakpoint($large-up) {
      border-bottom: 1px solid $color-black;
    }
  }
  .elc-search-icon-wrapper {
    height: 30px;
    top: -4px;

    & button {
      padding: 0;
      height: auto;
      &:focus-visible {
        outline-offset: 1px;
      }
    }
  }
  .elc-search-active-screen-wrapper {
    background-color: $color-grey-transparent;
    height: auto;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    @include breakpoint($landscape-up) {
      top: $gnav-height;
      overflow: hidden;
      .content-block-sitewide-banner--enabled & {
        top: $gnav-height + $sitewide-banner-height;
      }
    }
    .gnav-header-block--sticky &,
    .gnav-offers-hidden:not(.content-block-sitewide-banner--enabled) & {
      @include breakpoint($landscape-up) {
        top: $menu-height;
      }
    }
    .gnav-offers-hidden.content-block-sitewide-banner--enabled & {
      @include breakpoint($landscape-up) {
        top: $menu-height + $sitewide-banner-height;
      }
    }
    .gnav-search__tags-body & {
      overflow: hidden;
    }
    .elc-search-gnav-box {
      background-color: $color-cream;
      border-bottom: unset;
      height: 148px;
      padding: 56px 20px 0;
      margin-bottom: -15px;
      @include breakpoint($medium-up) {
        height: 164px;
        padding: 58px 64px 50px;
      }
      .elc-search-close-icon-wrapper {
        position: absolute;
        @include breakpoint($small-down) {
          #{$rdirection}: 16px;
          top: 16px;
        }
        @include breakpoint($medium-up) {
          #{$rdirection}: 24px;
          top: 24px;
        }
        .elc-search-close-icon {
          height: 20px;
          width: 20px;
        }
      }
      .elc-input-search-field {
        color: $black;
        font-family: $font--malone;
        font-size: 20px;
        height: 56px;
        padding: 0 16px;
        @include breakpoint($medium-up) {
          flex-grow: 1;
          font-size: 24px;
          padding: 0 24px;
          width: unset;
        }
        &::placeholder {
          color: $color-cream-stroke;
        }
      }
      .elc-search-results-button {
        @include button--dark;
        z-index: 2;
        height: 56px;
        line-height: 56px;
      }
    }
    .elc-search-results-wrapper {
      background-color: $color-cream;
      width: 100%;
      padding: 0 20px;
      @include breakpoint($medium-up) {
        column-gap: unset;
        padding: 0 64px 35px;
      }
      .elc-search-suggestion-container {
        @include breakpoint($medium-up) {
          grid-area: 1 / 1 / 3 / 2;
          & ~ .elc-search-results-message-wrapper {
            grid-area: unset;
            margin-#{$ldirection}: 40px;
            & ~ .elc-service-view-renderer {
              grid-area: unset;
            }
          }
        }
        .elc-search-suggestion-wrapper {
          padding: 0;
          @include breakpoint($medium-up) {
            border-#{$rdirection}: 1px solid $color-cream-stroke;
            height: 100%;
          }
        }
        .elc-search-suggestion-value {
          border: none;
          padding: 0 0 14px;
        }
        .elc-search-typeahead-count,
        .elc-search-suggestion-link {
          @include body-text;
          color: $black;
        }
        .elc-search-suggestion-link {
          &::after {
            content: '';
            background-color: $color-black;
            bottom: 4px;
            color: black;
            display: block;
            height: 1px;
            position: relative;
            width: 100%;
          }
          text-decoration: none;
          white-space: normal;
        }
        .elc-search-typeahead-count {
          padding-#{$ldirection}: 5px;
        }
        .elc-search-typeahead-title {
          margin-bottom: 15px;
          padding: 0;
          @include body-text;
          text-transform: none;
        }
      }
      .elc-service-view-renderer {
        grid-area: unset;
      }
      .elc-search-results-message-wrapper {
        padding: 0;
        .elc-search-no-result-message,
        .elc-search-result-adjustment-message {
          @include body-text;
          .elc-search-term-wrapper {
            font-weight: normal;
          }
        }
        h5 {
          @include title--h3;
          text-align: #{$ldirection};
          text-transform: capitalize;
        }
      }
      .elc-clickable-wrapper {
        &.elc-product-image-badge-wrapper {
          min-height: unset;
        }
      }
    }
    // Content search
    .elc-tabs {
      background-color: $color-cream;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      @include breakpoint($medium-up) {
        grid-template-areas: 'tabs panel';
        grid-template-columns: 2fr 5fr;
        grid-template-rows: auto;
        margin-top: 15px;
      }
      @include breakpoint($xlarge-up) {
        grid-template-columns: 1fr 5fr;
      }
      &__tab {
        @include title--h6;
        @include swap_direction(margin, 0 40px 0 0);
        color: $black;
        text-decoration: none;
        width: fit-content;
        @include breakpoint($medium-up) {
          @include swap_direction(margin, 0 50px 24px 64px);
        }
        @include breakpoint($large-up) {
          margin-#{$rdirection}: 0;
        }
        &--current,
        &:hover {
          @include link-underline;
          text-shadow: 0 0 0.65px;
        }
      }
      &__list {
        padding: 1px 20px;
        @include breakpoint($medium-up) {
          flex-flow: column;
          padding: 0;
        }
      }
      &__panels-wrapper {
        .elc-search-results-wrapper {
          @include breakpoint($medium-up) {
            @include swap_direction(padding, 0 64px 0 39px);
            border-#{$ldirection}: 1px solid $color-cream-stroke;
            grid-template-rows: auto auto;
            grid-template-areas: 'results-message' 'product-grid';
            grid-template-columns: 1fr;
            margin: 0 0 40px;
          }
          > .elc-search-suggestion-container {
            margin-top: 35px;
            > .elc-search-suggestion-wrapper {
              padding-bottom: 18px;
              @include breakpoint($medium-up) {
                padding: 0;
              }
              > .elc-search-typeahead-title,
              > .elc-search-suggestion-value {
                margin-bottom: 15px;
                padding: 0;
              }
              @include breakpoint($medium-up) {
                border-#{$rdirection}: none;
              }
            }
            &::after {
              background-color: $color-cream-stroke;
              content: '';
              height: 1px;
              position: relative;
              width: 100%;
              @include breakpoint($medium-up) {
                display: none;
              }
            }
            @include breakpoint($medium-up) {
              #{$ldirection}: 64px;
              margin-top: 0;
              position: absolute;
              top: 271px;
              width: auto;
            }
          }
          .elc-search-results-message-wrapper {
            padding-top: 34px;
            @include breakpoint($medium-up) {
              margin-#{$ldirection}: 0;
              padding: 0;
            }
            .elc-search-result-adjustment-message {
              @include breakpoint($medium-up) {
                padding: 0;
              }
            }
          }
          .elc-search-grid-wrapper {
            margin-top: 10px;
            @include breakpoint($medium-up) {
              margin-top: 0;
              .elc-search-result-adjustment-message {
                padding: 0;
              }
            }
          }
        }
      }
      &__panel {
        @include breakpoint($medium-up) {
          margin-bottom: 40px;
        }
        > .elc-search-results-message-wrapper,
        .elc-search-content-grid-wrapper {
          @include breakpoint($medium-up) {
            border-#{$ldirection}: 1px solid $color-cream-stroke;
          }
        }
        > .elc-search-results-message-wrapper {
          @include swap_direction(padding, 35px 20px 20px);
          @include breakpoint($medium-up) {
            @include swap_direction(padding, 0 64px 26px 39px);
            > .elc-search-result-overlay-message {
              margin: 0;
            }
          }
        }
        .elc-search-content-grid-wrapper {
          .elc-search-article {
            &__wrapper {
              &:last-child {
                margin-bottom: 60px;
              }
              @include breakpoint($medium-up) {
                border: none;
                margin-bottom: 20px;
                &:last-child {
                  margin-bottom: 20px;
                }
              }
            }
            &__info {
              @include breakpoint($medium-up) {
                align-items: baseline;
                justify-content: flex-start;
                margin: 26px 0 0;
              }
            }
            &__title {
              @include body-text;
              @include breakpoint($medium-up) {
                margin-bottom: 11px;
                line-height: 1;
              }
            }
            &__short-desc {
              display: none;
            }
            &__link {
              display: none;
            }
          }
          @include breakpoint($medium-up) {
            @include swap_direction(padding, 0 64px 0 39px);
          }
        }
      }
    }
  }
}

.elc-search-suggestion-container {
  .elc-search-suggestion-wrapper {
    padding: 0;
    @include breakpoint($medium-up) {
      border-#{$rdirection}: 1px solid $color-cream-stroke;
      height: 100%;
      padding-#{$ldirection}: 64px;
    }
  }
  .elc-search-suggestion-icon-wrapper {
    padding-#{$rdirection}: 10px;
  }
  .elc-search-suggestion-value {
    border: none;
    padding: 0 0 10px 0;
    text-align: #{$ldirection};
    .elc-search-in-suggestion {
      @include body-text;
    }
  }
  .elc-search-view-all-results-count,
  .elc-search-term-suggestion,
  .elc-search-suggestion-icon-wrapper {
    display: none;
  }
  .elc-search-typeahead-count,
  .elc-search-suggestion-link {
    @include body-text;
    color: $black;
  }
  .elc-search-suggestion-link {
    &::after {
      content: '';
      background-color: $color-black;
      bottom: 4px;
      display: block;
      height: 1px;
      position: relative;
      width: 100%;
    }
    text-decoration: none;
    white-space: normal;
  }
  .elc-search-typeahead-count {
    padding-#{$ldirection}: 5px;
  }
  .elc-search-typeahead-title {
    @include body-text;
    text-transform: none;
  }
  .elc-search-value {
    position: absolute;
    text-transform: capitalize;
    font-weight: bold;
    line-height: 1.8;
  }
}

.elc-search-active-screen-wrapper {
  .elc-product-item-wrapper.elc-product-detail-wrapper {
    min-height: auto;
  }
  .elc-grid-item-product {
    margin-bottom: 0;
  }
  .elc-product-brief-wrapper {
    background-color: $color-cream;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
    .elc-grid-item-product {
      margin-bottom: 0;
    }
    .elc-product-brief-detail-wrapper {
      height: auto;
      padding-bottom: 0;
      .elc-product-cta-wrapper {
        display: none;
      }
    }
  }
  .elc-grid-container,
  .elc-search-result-overlay-message {
    font-size: 14px;
    @include breakpoint($medium-up) {
      line-height: 1;
      margin: 0;
      min-height: auto;
    }
  }
  .elc-search-results-message-skeleton,
  .elc-search-grid-skeleton {
    padding: 0 8px;
  }
  .elc-search-suggestions-skeleton-item {
    padding-#{$rdirection}: 8px;
  }
  .elc-product-price-wrapper,
  .elc-product-rating-wrapper,
  .elc-product-size-wrapper,
  .elc-product-quick-view-button-wrapper,
  .elc-size-picker-container {
    display: none;
  }
  .elc-spinner-container {
    cursor: pointer;
  }
  button:hover {
    background: none;
  }
}

.elc-search-sort-mobile-wrapper select {
  text-align: $rdirection;
  text-align-last: $rdirection;
}

.elc-voice-search-wrapper {
  position: absolute;
  z-index: 1000;
  #{$rdirection}: $gnav-mobile-hpad--inner;
  top: $gnav-mobile-vpad--med;
  @include breakpoint($medium-up) {
    #{$rdirection}: 68px;
  }
  h2 {
    @include title--h2;
    margin: 15px 0;
  }
  p {
    @include body-text;
    margin: 0;
    &.elc-voice-search-term {
      text-align: center;
      text-transform: capitalize;
    }
  }
  .elc-search-voice-transcoder {
    padding: 15.5px 15px;
  }
  .elc-search-microphone-wrapper {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .elc-search-speak-wrapper {
    background-color: $color-cream;
    padding: 50px $gnav-mobile-hpad--inner;
  }
  .elc-searching-wrapper,
  .elc-error-capture-voice-wrapper,
  .elc-listen-message-wrapper,
  .elc-heading-allow-microphone {
    padding: 0 20px;
  }
  .elc-search-speak-icon-wrapper {
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 200px;
      height: 200px;
      opacity: 0.75;
      background-color: $color-cream;
      top: 0;
      animation: microphoneAnimation 2s linear infinite 0.4s;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      border: 3px solid $black;
      border-radius: 50%;
    }
  }
  @keyframes microphoneAnimation {
    0% {
      height: 100%;
    }
    100% {
      height: 0;
    }
  }
  .elc-search-speak-microphone-icon,
  .elc-microphone-icon {
    background: url(../../../../img/icons/src/microphone.svg) center no-repeat;
    mask: none;
    -webkit-mask: none;
  }
  .elc-microphone-icon {
    height: 25px;
    -webkit-mask-size: 25px;
    width: 25px;
  }
  .elc-search-speak-microphone-icon {
    width: 100px;
    height: 100px;
  }
  .elc-speak-now-message {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    text-align: center;
  }
}
